<template>
  <div>
    <div class="row mt-4">
      <div class="col-lg-4">
        <b-btn @click="getMap" variant="primary">{{labels.manageProjectMapLabels.syncButton}}</b-btn>
        <div v-show="mess" class="alert alert-secondary mt-3">
          {{mess}}
        </div>
        <sync-options v-model="selectedOption"></sync-options>
        <!-- <sync-state :sync-state="form.syncState" :change-counter="ChangeCounter" :error-message="form.errorMessage"></sync-state> -->
        <div class="mt-5" v-if="$store.getters['localDB/hasLocalProject'](project.id)">
          <div class="col-lg-6 mt-4">
            <b-btn @click="removeMap" variant="warning" v-if="isDeleting===false">{{labels.manageProjectMapLabels.removeButton}}</b-btn>
            <b-btn @click="removeMap" variant="danger" v-if="isDeleting!==false">{{labels.manageProjectMapLabels.removeButtonConfirm}}</b-btn>
          </div>
        </div>
      </div>
      <div class="col-lg-8" v-if="layerDocs">
        <div class="h5">{{labels.manageProjectMapLabels.listFormLayers}}</div>
        
        <ul class="list-group">
          
          <li v-for="lay in formsLayers" 
            class="list-group-item" 
            :class="{'list-group-item-dark':lay.is_basemap}" 
            :key="lay._id">
            <map-layers-layer :lay="lay" :project="project" @update="update()" @errors="errors=$event" :key="'layersUpdateKey'+lay.layersUpdateKey"></map-layers-layer>
          </li>
        </ul>
        <div v-if="message" class="alert alert-secondary">
          {{message}}
        </div>
        <div v-if="errors" class="alert alert-danger">
          {{errors}}
        </div>
        <div class="h5 mt-5">{{labels.manageProjectMapLabels.listLayers}}</div>
        <ul class="list-group">
          <li v-for="lay in layers" 
            class="list-group-item" 
            :class="{'list-group-item-dark':lay.is_basemap}" 
            :key="lay._id">
            {{lay.label}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import syncOptions from "../sync/syncOptions.vue";
import syncState from "../sync/syncState.vue";
import mapLayersLayer from './mapLayersLayer.vue';

export default {
  props: ["project"],
  components: {
    syncOptions,
    syncState,
    mapLayersLayer,
  },
  data() {
    return {
      mess: null,
      isDeleting: false,
      selectedOption:null,
      message:null,
      errors:null,
      layersUpdateKey:1,
    };
  },
  computed: {
    systemDoc() {
      return this.$store.state.systemDoc;
    },
    labels() {
      return this.$store.state.labels;
    },
    project_id() {
      return this.project.id;
    },
    layers(){
      return this.layerDocs.filter(x=>x.couchdb_form?false:true)
    },
    formsLayers(){
      const existingLayers = this.layerDocs.filter(x=>x.couchdb_form?true:false)
      this.layersUpdateKey++
      return this.forms.map(x=>{
        const ex1 = existingLayers.filter(y=>y.couchdb_form==x.id)
        if(ex1.length>0){
          //exist, so can only remove
          x.hasExisting=true
          x.layers=ex1
        }else{
          //we can add
          x.hasExisting=false
          x.layers=null
        }
        x.layersUpdateKey=this.layersUpdateKey
        return x
      })
    },
    forms(){
      return this.$store.getters['project/projectForms']
    }
  },
  asyncComputed:{
    layerDocs(){
      if(this.$store.state.project.db_project){
        return this.$store.dispatch('project/getLayersDocs').then(docs=>{
          this.layersUpdateKey++
          return docs.rows.map(x=>x.doc)
        })
      }
    }
  },
  methods: {
    getMap() {
      this.mess = "en cours";
      this.$PouchDB
        .replicate(
          this.$store.state.couchdbUrl + this.project.id,
          this.project.id,
          Object.assign(this.selectedOption, this.$store.getters.pouchDbOptions)
        )
        .on("complete", info => {
          this.mess = info;
        })
        .on("error", err => {
          // handle error
          this.mess = err;
        });
      this.$store.dispatch("localDB/addProjectLocal", this.project.id);
    },
    removeMap() {
      if (this.isDeleting) {
        this.isDeleting = false;
        this.mess = null;
        this.$store
          .dispatch("localDB/deleteProjectLocal", this.project.id)
          .then(rep => {
            this.mess = "Ok";
          })
          .catch(err => {
            this.mess = err;
            this.$store.dispatch('app_message_error',err)
          });
      } else {
        this.isDeleting = true;
      }
    },
    update(){
      this.$asyncComputed.layerDocs.update()
    }
  }
};

</script>

<style>
</style>
