
// ******** Malawi RLRS
import store from "../../index.js"

export default {
  name:'malawi',
  documentTitle:'Malawi RLRS Field',
  navbarTitle:'Malawi RLRS Field',//not used for now
  couchdbUrls: ['https://db.field.rlrs-mw.net/', 'https://192.168.99.200:6984/'],
  databaseSystem:'a_forms_system',
  pouhdb_replicate_batch_size:5, //default to 100, but crash in browser with photos.
  serial_position_active:false,
  inputGeopointDirectGpsClickAllowed:true,//this by pass the map.
  new_db_couchdb_security_users:"rap_admin",
  disableEditingOnOtherDevice: true,
  sql_params:{
    api_url: 'https://api.field.rlrs-mw.net/graphql',
    login_suffix: '@couchdb.sys',
    auto_sync_changes: true, // if we automatically create the record so nodesync will begin sync
    // todo: implement those options
    auto_sql_creation: true, // 
    auto_sql_creation_options:{ // same options possible as the sql.vue
      namebyform: true,
      selectManyTable: true,
      attachements: true,
      attachmentsView: true,
    }
  },
  allowDownloadSurveys: true,
  default_language: 'en',
  initTasks: [
    // TODO: we should return a funciton that returns a promise that does what it needs to do.
    {
      type: 'dispatch',
      action: 'localDB/addOtherDatabaseLocal',
      params: 'tiles_pleiades_v1'
    },
  ],
  editRoleSuggestOfflineMode: ['rap_surveyor', 'rap_supervisor'],
}
