<template>
  <div>
    <dot :color="subLayer.ol_style.stroke.color" v-if="subLayer?.ol_style?.stroke?.color"></dot>
    <b-btn variant="secondary" class="mx-2" size="sm" @click="isEditing=!isEditing" v-if="canEdit(subLayer)"><span class="mdi mdi-pencil"></span></b-btn>
    <b-btn variant="danger" class="mx-2" size="sm" @click="deleteSubLayer(subLayer)" v-if="canEdit(subLayer)"><span class="mdi mdi-trash-can"></span></b-btn>
    {{subLayer.label}}
    <div v-if="isEditing" class="card m-3 ml-5">
        <div class="card-header">
          Edit values and them save
        </div>
      <div class="card-body">
        <b-form-input v-model="subLayer.label"></b-form-input>
        <b-form-checkbox v-model="subLayer.visible" class="my-2">Visible</b-form-checkbox>
        <b-form-select v-model="subLayer.currentSurveyOption" :options="options" @click.stop=""></b-form-select>
        <p-color v-model="colors" class="my-2"></p-color>
        <b-btn variant="primary" @click="saveSubLayer(subLayer)" class="my-2 ml-4">Save</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { Swatches } from 'vue-color' // https://github.com/xiaokaike/vue-color
  import dot from '../utils/dots.vue'

  export default {
    name: "subLayer",
    components: {
      'p-color': Swatches,
      dot,
    },
    props: ["subLayer0",'project'],
    data() {
      return {
        isEditing: false,
        colors: '#ff0000',
        subLayer: JSON.parse(JSON.stringify(this.subLayer0)),
      }
    },
    computed: {
      dbProj(){
        return new this.$PouchDB(this.$store.state.couchdbUrl + this.project.id, this.$store.getters.pouchDbOptions)
      },
      optionsTranslation(){return this.$store.state.labels.map.surveyLayerVisibleOptions},
      options(){
        return [
          {value: 'onlyShowMySurvey', text: this.optionsTranslation.onlyShowMySurvey},
          {value: 'fadeOtherSurveys', text: this.optionsTranslation.fadeOtherSurveys},
          {value: 'allSurveys', text: this.optionsTranslation.allSurveys},
          {value: null, text: this.optionsTranslation.defaultVal},
        ]
      },
    },
    methods: {
      canEdit(subLayer){
        if(
          this.$store.getters.isAdmin &&
          subLayer?.ol_style?.image?.fill?.color &&
          subLayer?.ol_style?.stroke?.color
        ){
          return true
        }
        return false
      },
      deleteSubLayer(subLayer){
        if(this.$store.getters.isAdmin){
          subLayer['_deleted']=true
          this.dbProj.bulkDocs([subLayer]).then(()=>{
            this.$emit('update')
          }).catch(err=>{
            this.$store.dispatch('app_message_error',err)
            this.$emit('errors',err)
          })
        }
        return false
      },
      saveSubLayer(subLayer){
        if(this.$store.getters.isAdmin){
          this.isEditing=false
          subLayer.ol_style.image.fill.color = this.colors.hex
          subLayer.ol_style.stroke.color = this.colors.hex
          this.dbProj.bulkDocs([subLayer]).then(()=>{
            this.$emit('update')
          }).catch(err=>{
            this.$store.dispatch('app_message_error',err)
            this.$emit('errors',err)
          })
        }
        return false
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>