// as project in index.js

//represent the current project
//one project has
//one map - but many layers
//many forms

import cuid from 'cuid'
import store from './index.js'


export default{
  namespaced: true,
  modules:{

  },
  state:{
    project_id:null,
    db_project:null,
  },
  getters:{
    project(state,getters,rootState,rootGetters){
      if(rootState.systemDoc){
        return rootState.systemDoc.projects.filter(x => x.id == state.project_id)[0]
      }
    },
    isOnlineDb(state){
      if(state.db_project){
        if (state.db_project.name.indexOf('http')==0){
          return true
        }
        return false
      }
      return undefined
    },
    projectForms(state,getters){
      if(getters.project){
        return getters.project.forms.map(x=>{
          if(x.deleted!==true){
            return x
          } 
        }).filter(x=>x!==undefined)
      }
    }
  },
  mutations:{

  },
  actions:{
    createNewProjectConfig(context,project_id){
      context.state.project_id=project_id
      let db=context.rootState.dbDatabaseSystem
      //create a database
      return context.dispatch('localDB/getDatabase',
          {dbName:project_id,alwaysUseRemoteDb:true},
          { root: true }).then(rep=>{
        context.state.db_project=rep.db
        return rep.db.security().fetch()
      }).then(()=>{
        const security=context.state.db_project.security()
        security.admins.roles.add(context.rootState.settings.new_db_couchdb_security_admins)
        security.members.roles.add(context.rootState.settings.new_db_couchdb_security_users)
        return security.save()
      }).then(()=>{
        return context.rootState.dbDatabaseSystem.get('default_map_config')
      }).then(doc=>{
        //create default map_config
        doc._id='map_config'
        delete doc._rev
        return context.state.db_project.put(doc)
      }).then(()=>{
        // get all the base maps docs
        return context.rootState.dbDatabaseSystem.allDocs({include_docs: true, startkey: "map_layer",endkey: "map_layer\ufff0"})
      }).then(docs=>{
        if(docs.rows.length>0){
          const newDocs = docs.rows.map(x=>{
            const doc=x.doc
            delete doc._rev
            return doc
          })
          return context.state.db_project.bulkDocs(newDocs)
        }
        return Promise.resolve()
      }).then(()=>{
        return db.get('config')
      }).then(doc=>{
        //add configDb to this project
        let project=doc.projects.filter(x=>x.id==project_id)[0]
        project.configDb=project_id
        return db.put(doc)
      }).then(()=>{
        // if all ok, then end and reread the config doc to update the UI
        return context.dispatch('getSystemDoc',null,{root:true})
      }).then(()=>{
        // if all ok, then end and reread the config doc to update the UI
        return context.dispatch('replicateDB',project_id,{root:true})
      })
      
      
    },
    setProjectId(context,project_id){
      context.state.project_id=project_id
      // get the project database
      return context.dispatch('localDB/getDatabase',
          {dbName:project_id,db:context.state.db_project},
          { root: true }).then(rep=>{
        context.state.db_project=rep.db
        // console.log(rep.db);
        
        if(!rep.sameAsProvided){
          //cleanup
          
        }
        return Promise.resolve('Ok')
      }).catch(()=>Promise.resolve())//we don't want an error for this.
    },
    getMap(context){
      return context.state.db_project.get('map_config').then(config=>{
        return context.dispatch('map/getMap',config,{root:true})
      }).then(map=>{
        return context.dispatch('getLayers')
      }).catch(err=>{
        context.dispatch('app_message_error','Error in getMap',{root:true})
        context.dispatch('app_message_error',err,{root:true})
      })
    },
    getLayersDocs(context){
      return context.state.db_project.allDocs({
        include_docs:true,
        attachments: false,
        startkey:'map_layer_',
        endkey:'map_layer_\ufff0'
      })
    },
    getLayers(context){
      context.dispatch('getLayersDocs').then(docs=>{
        // the most up will be at the bottom
        const docs2 = docs.rows.sort((a,b) =>{
          if(a.order && b.order){
            if(a.order <  b.order){
              return 1
            }else if(a.order >  b.order){
              return -1
            }
          }else if (a.order){
            return 1
          }else if (b.order){
            return -1
          }
          return 0
        })
        return Promise.all(docs2.map(doc0=>{
          const doc = doc0.doc
          if (doc.hasOwnProperty('active')===false || doc.active){
            // console.log(doc._id);
            let promise=[] // hold if necessary the promise for attachment
            if (doc.hasOwnProperty('_attachments')){
              //has an attachment that we want to fetch
              // console.time(doc._id)
              promise.push(context.state.db_project.getAttachment(doc._id,Object.keys(doc._attachments)[0] ).then(blob=>{
                doc.url= URL.createObjectURL(blob);
                  // console.timeEnd(doc._id)
                return Promise.resolve(true)
              }))
            }
            return Promise.all(promise).then(rep=>{
              // TODO: ensure when we do this the order is maintened - because of promise and attchment, we think it's not always owrking at the moment.
              return context.dispatch('map/addLayer',doc,{root:true})
            })
          }else{
            return Promise.resolve()
          }
        }))
      })
    },
    getBaseLayers(context){
      //nothing for now
      return Promise.resolve(true)
    },
    editedSurvey(context,params){
      //do here as we don't want pollute map.js with survey specific actions.
      //not perfect but at least work locally.
      //we want to remove the survey if edited... useful, because the chage on the view don't get if the geom is delete. but yes if the survey is deleted...
      //TODO: track in the global change of the couchDB... but more complicated...
      if(context.rootState.map && context.rootState.map.layers){
        context.rootState.map.layers.map(lay_id=>{
          let lay=context.rootState.map[lay_id]
          if(lay.config.type_store=='layer_vector_couchdb' && lay.config.couchdb_form==params.form_id){
            context.dispatch('map/'+lay_id+'/removeSurveyId',params.id,{root:true})
          }
        })
      }
    },
    getImageryDbs(context){
      let response=[]
      return context.dispatch('getLayersDocs').then(docs=>{
        let ImageryCouch=docs.rows.filter(x=>x.doc.type_store=='layer_imagery_couchdb')
        return Promise.resolve(ImageryCouch.map(x=>x.doc.couchdb_name))
      })
    },
    getImageryDocs(context){
      let response=[]
      return context.dispatch('getLayersDocs').then(docs=>{
        let ImageryCouch=docs.rows.filter(x=>x.doc.type_store=='layer_imagery_couchdb')
        return Promise.resolve(ImageryCouch.map(x=>x.doc))
      })
    },
    addLayerDoc(context,layerDoc){
      let db=context.state.db_project
      return db.put(layerDoc)
    },
    // switchBaseLayer(map0,layerdef,isFirst) {
    //     let layColl = map0.getLayers();
    //     if(isFirst===false){
    //         layColl.removeAt(0)
    //     }
    //     layColl.insertAt(0, layerdef.layer);
    // }
  }
}
