
const defaultSettings={
  version: "5.90.19",
  // the rest is default values, should probably remove the ones that should be implemented
  documentTitle: "AECOM",
  navbarTitle: "AECOM", //not used for now
  couchdbUrls: ["https://dbforms.aecom.space/"], //,'http://192.168.50.2:5984/'], // we pass many as we want to support mobile field server
  databaseSystem: "forms_system",
  pouhdb_replicate_batch_size: 5, //default to 100, but crash in browser with photos.
  serial_position_active: false,
  inputGeopointDirectGpsClickAllowed: true, //this by pass the map.
  new_db_couchdb_security_users: "_admin",
  new_db_couchdb_security_admins: "_admin",
  default_language: "fr",
  autoCreateReplication: true, // the replication document _replicator/replicate_forms_system and _replicator/replicate_froms_system_r must exists. and 2 or more couchdbUrls must be defined
  allowDownloadSurveys: false, // TODO: Should be more in each form settings... or could override...
  deletePhotoOnDelete: false,
  disableEditingOnOtherDevice: false,
  tableStatsViews:{},
  role_can_edit_all_survey: null, // when edit_user_see_only_his_survey true, only admin can see all surveys of the system. Except if this is setup, this role will be able to see all surveys of the system.
}

// represent the setting of the app.
///some settings can be changed by the user

const projectVar = window._env_.PROJECT
import projects from "./projects";

// TODO: think about putting all project params in a env file... better to avoid all project having params from other projects.
// TODO: OR have the corresponding js file copied on build to env-config.js
const projectSettings = projects[projectVar]

export default {
  namespaced: true,
  state: Object.assign(
    defaultSettings,
    projectSettings
  ),
  getters: {},
  mutations: {},
  actions: {}
};
