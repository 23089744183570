<template>
  <b-list-group class="mt-1">
    <b-list-group-item active class="py-0">
      {{label}}
    </b-list-group-item>
    <b-list-group-item :to="feature.get('id_survey')?`/forms/${form_id}/edit/${feature.get('id_survey')}`:''" v-for="(feature,i) in features" :key="'identity-'+layer_id+i" class="py-1">
      <span>{{getFeatureText(feature)}}</span>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import base from './_base.js'

export default{
  name:'map-identity-layer',
  mixins:[base],
  props:['layer_id','features'],
  data(){
    return{
    }
  },
  computed:{
    label(){return this.$store.getters['map/'+this.layer_id+'/label']},
    visible(){return this.$store.getters['map/'+this.layer_id+'/visible']},
    form_id(){return this.$store.getters['map/'+this.layer_id+'/config'].couchdb_form}
  },
  methods:{
    init(){
    },
    getFeatureText(feature){
      if(this.$store.getters['map/'+this.layer_id+'/config'].identity){
        return this.$store.getters['map/'+this.layer_id+'/config'].identity.fields.map(x=>{
          return feature.getProperties()[x]
        }).filter(x=>!!x).join("-")
      }else if(feature.getProperties()['map_label']){
        return feature.getProperties()['map_label']
      }else if(feature.getProperties()['id_survey']){
        return feature.getProperties()['id_survey']
      }else{
        return feature.getId()
      }
    },
  }
}
</script>
