// dynamically registered inside projects - there can be multiple of those.

// represent a layer in the project map
// this provide additional functions for each one of them

export default {
  namespaced: true,
  state: {
    className:'layer',
    config:null,
    map_layer:null,
    visible:false,
  },
  getters: {
    label(state){return state.config.label},
    visible(state){return state.visible},
    config(state){return state.config}
  },
  mutations: {
    setVisible(state, visible){
      state.visible=visible
      state.map_layer.setVisible(visible);
    },
    toggleVisible(state){
      state.visible=!state.visible
      state.map_layer.setVisible(state.visible);
    }
  },
  actions: {
    define_from_config(context,config){
      context.state.config=config
      context.state.visible=config.visible
      return context.dispatch('define_from_config_sub',config).then(rep=>{
        //post layer initiation
        if(context.state.config.extent && context.state.map_layer){
          context.state.map_layer.setExtent(context.state.config.extent)
        }
        return Promise.resolve(rep) // reurn the same value
      })
    },
    define_from_config_sub(context,config){
      //should be overrided by the implementation.
      //created here to ensure it's called if not implemented
      return Promise.resolve(true)
    },
    addToMap(context, map){
      if(context.state.config.is_basemap){
        //TODO: verify if more than one...
        //or add all and make them unvisible... to see....
        //if(context.state.visible===true){
        map.getLayers().insertAt(0,context.state.map_layer)
        //}
      }else{
        map.addLayer(context.state.map_layer)
      }
    },
    getFeatures(context,coordinate){
      return Promise.resolve([])
      // we define here so it's the decision for each type to implement or not. Could be implemented on imagery if we decide to deal with wms as imagery...
    },
    getFeaturesAtPixel(context,pixel){
      return Promise.resolve([])
      // we define here so it's the decision for each type to implement or not. Could be implemented on imagery if we decide to deal with wms as imagery...
    },
    mapActivated(context){
      // we define here so it's the decision for each type to implement or not. Mostly for vector layer at the moment.
      return Promise.resolve(true)
    },
  },

}
